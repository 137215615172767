@use '@simon/core/styles/colors.module';

.chip {
  font-size: 10px;
  border-radius: 15px;
  display: inline-block;
  padding: 2px 6px;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  background: colors.$lightBlue;
}
