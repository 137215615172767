@use '@simon/core/styles/colors.module';

.divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border: 0;
  border-top: 1px solid colors.$gray15;
  opacity: 1;
}
