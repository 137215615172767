@use '~@simon/core/styles/vars.module';

.wrapper {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin-bottom: 30px;
}

.backArrowWrapper {
  position: absolute !important;
  top: 20px !important;
  left: 20px !important;
}

.title {
  font-size: 1.4em;
  font-weight: 400;
  color: #555;
  text-align: center;

  &.sm {
    font-size: 1.2em;
  }
}

.subtitle {
  font-size: 1em;
  font-weight: 300;
  color: #555;
  text-align: center;
  margin: 10px 0 0 0;
  max-width: 70%;
}
