@use '~@simon/core/styles/vars.module';

.securityQuestionLabel {
  display: block;
  font-weight: vars.$font-weight-medium;
  margin-bottom: 10px;
}

.oktaPushContainer {
  display: flex;
  align-items: center;
}

.or {
  margin: 20px 0;
}

.verifyFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.verifyGoBack {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: -20px;

  p {
    font-size: 14px;
    margin-top: 15px;
  }

  svg {
    margin-right: 5px;
    width: 20px;
  }
}
