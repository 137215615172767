@use '~@simon/core/styles/vars.module';
@use '~@simon/core/styles/colors.module';

.mainRow {
  border-radius: 10px;
  box-shadow: 0 0 100px rgb(0 0 0 / 20%);
  margin: 10px;
  overflow: hidden;
  flex-flow: column wrap;

  @media all and (min-width: vars.$simon-screen-sm) {
    flex-flow: row wrap;
    max-width: vars.$simon-screen-xl;
    margin: 20px auto;
  }
}

.jumboCol {
  background: linear-gradient(-45deg, #3b4669, #1f2a4f);
  display: flex !important;
  align-items: center;
  width: 100%;

  @media all and (min-width: vars.$simon-screen-md) {
    min-height: 700px;
  }
}

.jumboColContent {
  padding: 20px;
  width: 100%;
  text-align: center;

  a {
    display: inline-block;
    position: relative;

    .envPill {
      position: absolute;
      top: 0;
      right: -5px;
      transform: translateX(100%);
    }
  }

  hr {
    margin: 25px 0;
    border: none;
    border-bottom: 1px solid darken(#999, 20%);
    display: none;
  }
  h1 {
    margin: 0;
    font-weight: 400;
    font-size: 1.5em;
    padding: 0 15px;
    color: #fff;
    display: none;
  }

  @media all and (min-width: vars.$simon-screen-sm) {
    hr,
    h1 {
      display: block;
    }
  }
}

.rightCol {
  background: #fff;
  width: 100%;

  @media all and (min-width: vars.$simon-screen-md) {
    width: auto;
    min-height: 700px;
  }
}

.rightColContent {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding: 40px 30px;

  @media all and (min-width: vars.$simon-screen-md) {
    padding: 80px 40px 40px 40px;
  }
  @media all and (min-width: vars.$simon-screen-lg) {
    padding-left: 55px;
    padding-right: 55px;
  }

  .help {
    font-size: 0.9rem;
    margin-top: 65px;
    text-align: center;

    @media all and (min-width: vars.$simon-screen-sm) {
      margin-top: auto;
    }

    strong {
      display: block;
      font-weight: 500;
      margin-bottom: 6px;
    }
    a {
      display: block;
      margin-bottom: 5px;
    }
    .dot {
      display: none;
      color: #555;
      margin: 0 10px;
    }

    @media all and (min-width: vars.$simon-screen-md) {
      .dot,
      a {
        display: inline-block;
      }
    }
  }
}

.submitError {
  font-size: 1em;
  margin: 20px 0 5px 0;
  color: colors.$dangerColor;
}

.footerRow {
  margin-top: 35px;
  font-size: 0.8rem;
  a {
    display: inline-block;
    margin-bottom: 5px;
  }
}

.copyright {
  font-size: 0.8rem;
  color: colors.$greyLight;
  text-align: center;
}

.disclaimers {
  margin: 0 auto;
  font-size: 0.8rem;
  color: colors.$greyLight;
  text-align: center;
  padding: 20px;

  @media all and (min-width: vars.$simon-screen-sm) {
    max-width: vars.$simon-screen-xxl;
  }
}
