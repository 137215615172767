@media print {
  .ant-modal-close,
  .ant-modal-footer {
    display: none !important;
  }

  .ant-modal-header {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-bottom: 0 !important;
  }

  .ant-modal-wrap {
    position: absolute;
    bottom: auto;
  }

  .ant-modal-content {
    box-shadow: none;
  }

  .ant-modal-body {
    padding: 0 !important;
  }
}
