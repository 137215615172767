@use '@simon/core/styles/colors.module';

.dropdownToggle {
  &.split {
    padding-left: 1rem;
    padding-right: 1rem;

    &.primary {
      border-left-color: colors.$blue70;
    }

    &.branding {
      border-left-color: colors.$blue60;
    }

    &.danger {
      border-left-color: colors.$red70;
    }

    &.ghost {
      border-left-color: colors.$white;
    }
  }
}

.caret {
  transition: transform 0.1s;

  &.flipCaret {
    transform: rotate(180deg);
    margin-bottom: 3px;
  }
}
