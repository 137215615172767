@use 'sass:color';

// TODO: Deprecated. Use new color palette defined below, e.g. $blue40, $gray10
$black: #000;
$iCapitalBlue: #0077aa;
$lightBlue: #4a90e2;
$lightBlueFontColor: white;
$darkBlue: #2f66a5;
$darkBlueNavbar: #34455b;
$darkBlueGradientStart: #232b55;
$sanMarinoBlue: #3b67a0;
$pictonBlue: #2c90e9;
$lochmaraBlue: #027ac1;
$havelockBlue: #5990dc;
$darkBlueGradientEnd: #3e4d85;
$darkBlueGradient: linear-gradient(
  299.08deg,
  $darkBlueGradientEnd 0%,
  $darkBlueGradientStart 100%
);
$lightBlueGradient: linear-gradient(294.42deg, #4d63ab -3.57%, #2c3e70 41.12%);
$white: #ffffff;
$sunOrange: #faad14;
$lightYellow: #fffbe6;
$burntYellow: #9f7c24;

$highlandGreen: #63915d;
$darkBlueFontColor: white;
$lightGrayGradientInverted: linear-gradient(180deg, #fff 0%, #f6f6f6 100%);
$textActionColor: #739cbf;
$navyBlue: #333a53;
$accentBlue: #2d3f72;
$accentGray: #f7f7f7;
$silverGrey: #c8c8c8;
$silver: #c1c8d0;
$darkFernGreen: #0c6013;
$eucalyptusGreen: #24a148;
$osloGrey: #919397;
$silverSandGrey: #bbbec2;
$lightSilverGrey: #c4c4c4;
$darkSilverGrey: #bfbfbf;
$manateeGrey: #858998;
$dustyGrey: #979797;
$defaultFontColor: $navyBlue;
$successColor: #00a500;
$dangerColor: #cc0000;
$greyLight: #9e9e9e;
$greyDisclaimers: #9e9e9e;
$gainsboro: red;
$torchRed: #f5222d;
$azureBlue: #0091ff;
$neutral90: #252b32;
$darkGreen: #089051;
$darkRed: #cc2718;

$inputBorderColor: $lightSilverGrey;
$inputHoverBorderColor: color.adjust($inputBorderColor, $lightness: -20%);
$inputFocusBorderColor: $darkBlue;
$inputPlaceholderColor: #9c9c9c;
$inputDisabledBackground: #f2f2f2;
$inputDisabledText: #a0a0a0;
$inputSelectBorder: #cccccc;

$rowHoverBackground: #f4f9ff;
$rowHoverBorderColor: #c1ddff;

$positiveColor: #417505;
$negativeColor: #d0021b;

$dividerColor: #dedede;

// Select widgets related vars
$multiValueTagBackground: color.adjust(#bbd5fd, $alpha: -0.8);

$templateZoneBorderColor: #ea6c01;
$templateZoneLabelBackgroundColor: #fbe2cc;

$veryLightGrey: #cecece;
$lightGrey: #e2e2e2;
$lighterGrey: #f6f6f6;
$darkGrey: #b0b0b0;
$mediumBlue: #4a8fe2;

$envestnetGreen: #03737f;

/* * * * * * * * * * * * * * * * * * *
 *  New color design system (2022)   *
 * * * * * * * * * * * * * * * * * * */
$white: #ffffff;
$black: #000000;

$blue05: #f4f9ff;
$blue10: #e8f3ff;
$blue20: #d4e4f8;
$blue30: #afcef2;
$blue40: #80b1eb;
$blue50: #4a8fe2;
$blue55: #3d7bc4;
$blue60: #2f66a5;
$blue70: #2d5088;
$blue80: #2c3e70;
$blue90: #1d294a;
$blue100: #101628;

$green05: #f6fbf8;
$green10: #e8f7ed;
$green20: #cfead7;
$green30: #9fd6ae;
$green40: #6abf83;
$green50: #24a148;
$green60: #1c7e38;
$green70: #155d2a;
$green80: #104720;

$yellow05: #fffaeb;
$yellow10: #fff4d1;
$yellow20: #ffe083;
$yellow30: #ffc106;
$yellow40: #f5b800;
$yellow50: #daa300;
$yellow60: #a37a00;
$yellow70: #7a5c00;
$yellow80: #523d00;

$amber05: #fff5eb;
$amber10: #ffd9ad;
$amber20: #ffb35c;
$amber30: #ff9219;
$amber40: #ff6d1f;
$amber50: #f54900;
$amber60: #cc3d00;
$amber70: #913300;
$amber80: #6b2600;

$red05: #fff8f7;
$red10: #fdf2f1;
$red20: #f9dbd9;
$red30: #f4bcb8;
$red40: #ee9892;
$red50: #e46258;
$red60: #d91f11;
$red70: #a1170d;
$red80: #7a110a;

$teal05: #f8fbfb;
$teal10: #e7f7f8;
$teal20: #c7e8ea;
$teal30: #96d4d8;
$teal40: #64c0c6;
$teal50: #509a9e;
$teal60: #3e777b;
$teal70: #2e585b;
$teal80: #224143;

$purple05: #eaedff;
$purple10: #d7ddff;
$purple20: #b4bfff;
$purple30: #8a9cff;
$purple40: #697ff5;
$purple50: #5065d7;
$purple60: #4a5dc0;
$purple70: #415690;
$purple80: #354675;

$violet05: #fbf9ff;
$violet10: #f6f0ff;
$violet20: #eadcfe;
$violet30: #d7befe;
$violet40: #c29bfd;
$violet50: #a66dfd;
$violet60: #8a3ffc;
$violet70: #662fba;
$violet80: #4d238d;

$silver05: #f9fafb;
$silver10: #f4f5f7;
$silver20: #dde2e7;
$silver30: #c4cdd6;
$silver40: #a3b0be;
$silver50: #7b8ea3;
$silver60: #66798f;
$silver70: #425468;
$silver80: #333a53;

$gray05: #fbfbfb;
$gray10: #f5f5f5;
$gray13: #e9e9e9;
$gray15: #d8d8d8;
$gray20: #c2c2c2;
$gray30: #a3a3a3;
$gray40: #8f8f8f;
$gray50: #7a7a7a;
$gray60: #666666;
$gray70: #4a4a4a;
$gray72: #b8b8b8;
$gray80: #36393c;
$gray90: #252b32;
$gray100: #121619;

$dark: #2e4250;

$purpleGradient: linear-gradient(131.66deg, #152d49 1.43%, #2c3e70 75.66%);
$blueGradient: linear-gradient(135deg, #2c3e70 0%, #4a8fe2 100%);

:export {
  sunOrange: $sunOrange;
  lightYellow: $lightYellow;
  burntYellow: $burntYellow;
  lightBlue: $lightBlue;
  sanMarinoBlue: $sanMarinoBlue;
  pictonBlue: $pictonBlue;
  lochmaraBlue: $lochmaraBlue;
  silver: $silver;
  silverGrey: $silverGrey;
  lightSilverGrey: $lightSilverGrey;
  darkSilverGrey: $darkSilverGrey;
  silverSandGrey: $silverSandGrey;
  darkGrey: $darkGrey;
  dustyGrey: $dustyGrey;
  darkBlue: $darkBlue;
  darkBlueGradientStart: $darkBlueGradientStart;
  darkBlueGradientEnd: $darkBlueGradientEnd;
  navyBlue: $navyBlue;
  defaultFontColor: $defaultFontColor;
  positiveValue: $positiveColor;
  negativeValue: $negativeColor;
  havelockBlue: $havelockBlue;
  highlandGreen: $highlandGreen;
  darkFernGreen: $darkFernGreen;
  eucalyptusGreen: $eucalyptusGreen;
  multiValueTagBackground: $multiValueTagBackground;
  greyLight: $greyLight;
  inputDisabledBackground: $inputDisabledBackground;
  inputDisabledText: $inputDisabledText;
  inputSelectBorder: $inputSelectBorder;
  /* * * * * * * * * * * * * * * * * * *
   *  New color design system (2022)   *
   * * * * * * * * * * * * * * * * * * */
  white: $white;
  black: $black;

  blue05: $blue05;
  blue10: $blue10;
  blue20: $blue20;
  blue30: $blue30;
  blue40: $blue40;
  blue50: $blue50;
  blue55: $blue55;
  blue60: $blue60;
  blue70: $blue70;
  blue80: $blue80;
  blue90: $blue90;
  blue100: $blue100;

  green05: $green05;
  green10: $green10;
  green20: $green20;
  green30: $green30;
  green40: $green40;
  green50: $green50;
  green60: $green60;
  green70: $green70;
  green80: $green80;

  yellow05: $yellow05;
  yellow10: $yellow10;
  yellow20: $yellow20;
  yellow30: $yellow30;
  yellow40: $yellow40;
  yellow50: $yellow50;
  yellow60: $yellow60;
  yellow70: $yellow70;
  yellow80: $yellow80;

  amber05: $amber05;
  amber10: $amber10;
  amber20: $amber20;
  amber30: $amber30;
  amber40: $amber40;
  amber50: $amber50;
  amber60: $amber60;
  amber70: $amber70;
  amber80: $amber80;

  red05: $red05;
  red10: $red10;
  red20: $red20;
  red30: $red30;
  red40: $red40;
  red50: $red50;
  red60: $red60;
  red70: $red70;
  red80: $red80;

  teal05: $teal05;
  teal10: $teal10;
  teal20: $teal20;
  teal30: $teal30;
  teal40: $teal40;
  teal50: $teal50;
  teal60: $teal60;
  teal70: $teal70;
  teal80: $teal80;

  purple05: $purple05;
  purple10: $purple10;
  purple20: $purple20;
  purple30: $purple30;
  purple40: $purple40;
  purple50: $purple50;
  purple60: $purple60;
  purple70: $purple70;
  purple80: $purple80;

  violet05: $violet05;
  violet10: $violet10;
  violet20: $violet20;
  violet30: $violet30;
  violet40: $violet40;
  violet50: $violet50;
  violet60: $violet60;
  violet70: $violet70;
  violet80: $violet80;

  silver05: $silver05;
  silver10: $silver10;
  silver20: $silver20;
  silver30: $silver30;
  silver40: $silver40;
  silver50: $silver50;
  silver60: $silver60;
  silver70: $silver70;
  silver80: $silver80;

  gray05: $gray05;
  gray10: $gray10;
  gray13: $gray13;
  gray15: $gray15;
  gray20: $gray20;
  gray30: $gray30;
  gray40: $gray40;
  gray50: $gray50;
  gray60: $gray60;
  gray70: $gray70;
  gray80: $gray80;
  gray90: $gray90;
  gray100: $gray100;

  dark: $dark;

  purpleGradient: $purpleGradient;
  blueGradient: $blueGradient;
}
