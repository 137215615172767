@use '@simon/core/styles/colors.module';

.dropdownHeader {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: colors.$gray50;
  white-space: nowrap;
}
