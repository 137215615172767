@use '~@simon/core/styles/vars.module';
@use '~@simon/core/styles/colors.module';

.alertContainer {
  display: flex;
  align-items: center;
  padding: 0px 10px;
  margin-bottom: 20px;
}

.alertContainerError {
  background-color: colors.$red10;
  border-bottom: 1px solid colors.$red60;
}

.alertContainerSuccess {
  background-color: colors.$green05;
  border-bottom: 1px solid colors.$green60;
}

.errorSvg {
  margin-top: 5px;
  margin-left: 7px;
}

.successSvg {
  margin-top: 8px;
}

.alertMessage {
  font-size: 1em;
  margin-top: 15px;
  margin-left: 10px;
}
