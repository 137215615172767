@use '@simon/core/styles/colors.module';

.dropdownItem {
  position: relative;
  display: flex;
  color: colors.$navyBlue;
  padding: 6px 35px 6px 15px;
  align-items: center;
  min-height: 30px;
  cursor: pointer;
  line-height: 20px;
  transition: box-shadow 0.15s ease-in-out;
  user-select: none;

  &.disabled {
    color: colors.$gray30;
  }

  &.dropdownButton {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    height: 20px;
  }

  &:hover,
  &:focus {
    outline: none;
    color: colors.$navyBlue;
    background-color: rgba(colors.$navyBlue, 0.06);
  }

  &:active {
    color: colors.$navyBlue;
    background-color: rgba(colors.$navyBlue, 0.1);
  }
}
