@use '@simon/core/styles/colors.module';

$input-height: 40px;
$input-height-compact: 32px;

$input-padding-y: 10px;
$input-padding-x: 10px;

$input-border-color: colors.$gray15;
$input-border-radius: 4px;

$input-transition: box-shadow 200ms cubic-bezier(0.2, 0.8, 0.4, 1),
  border-color 200ms cubic-bezier(0.2, 0.8, 0.4, 1);

%inputBase {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  min-height: $input-height;
  color: colors.$gray90;
  font-size: 14px;
  line-height: 20px;
  background-color: colors.$white;
  background-image: none;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
  transition: $input-transition;

  &::placeholder {
    text-overflow: ellipsis;
  }

  &:focus,
  &.focused {
    border: 1px solid colors.$blue50;
    box-shadow: 0 0 0 3px colors.$blue20;

    &.error {
      box-shadow: 0 0 0 3px colors.$red20;
      z-index: 2;
    }
  }

  &.error {
    border-color: colors.$red60;
  }

  &[disabled],
  &.disabled {
    color: colors.$gray15;
    cursor: not-allowed;
  }

  &[readonly],
  &.readOnly {
    background-color: colors.$gray10;
    color: colors.$gray50;
  }
}

.inputBase {
  @extend %inputBase;
}

.input {
  @extend %inputBase;
  padding: 0 $input-padding-x;

  // Sizing for basic input
  &.compact {
    min-height: $input-height-compact;
    padding: 0 10px;
  }
}

.label {
  display: block;
  margin-bottom: 8px;
  color: colors.$gray50;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.required {
  padding-left: 4px;
  color: colors.$red60;
}

.helperText {
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
  color: colors.$gray50;

  &.error {
    color: colors.$red60;
  }
}

.wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}

.inputGroupWrapper {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.inputGroup {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  > .input {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;

    &:focus {
      z-index: 2;
    }
  }

  // Sizing for input group
  &.compact {
    min-height: $input-height-compact;
  }

  // with right addon
  > .affixWrapper:not(:last-child),
  > input:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  // with left addon
  > .affixWrapper:not(:first-child),
  > input:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  // with both left & right addons
  > input:not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  // Prevent double borders when buttons are next to each other
  > .affixWrapper:not(:first-child),
  > input:not(:first-child) {
    margin-left: -1px;
  }
}

.inputGroupAddonText {
  display: flex;
  align-items: center;
  padding: 0 $input-padding-x;
  color: colors.$gray90;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;

  svg {
    vertical-align: middle;
  }

  // addonBefore
  &:first-child {
    margin-right: -1px;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  // addonAfter
  &:last-child {
    margin-left: -1px;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

// Styles for Button component
.inputGroupAddonButton {
  position: relative;
  padding-right: 20px;
  padding-left: 20px;
  z-index: 1; // ensure focus outline is always on top of the input field

  &:focus {
    z-index: 3;
  }

  // addonBefore
  &:first-child {
    margin-right: -1px;
    border-radius: $input-border-radius 0 0 $input-border-radius;
  }

  // addonAfter
  &:last-child {
    margin-left: -1px;
    border-radius: 0 $input-border-radius $input-border-radius 0;
  }
}

// Styles for Dropdown and ButtonGroup components (i.e. nested Button)
.inputGroupAddonButtonGroup {
  [role='button'],
  [type='button'] {
    position: relative;
    padding-right: 20px;
    padding-left: 20px;
    z-index: 1;

    // Apply different padding for Split button toggle
    + [role='button'][aria-haspopup='true'],
    + [type='button'][aria-haspopup='true'] {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }

  // addonBefore
  &:first-child {
    border-radius: $input-border-radius 0 0 $input-border-radius;

    [role='button'],
    [type='button'] {
      margin-right: -1px;
      border-radius: $input-border-radius 0 0 $input-border-radius;
    }
  }

  // addonAfter
  &:last-child {
    border-radius: 0 $input-border-radius $input-border-radius 0;

    [role='button'],
    [type='button'] {
      margin-left: -1px;
      border-radius: 0 $input-border-radius $input-border-radius 0;
    }
  }
}

// Styles for Select component
.inputGroupAddonSelect {
  max-width: 80px;
  flex: 1;

  &:focus {
    z-index: 1;
  }

  // addonBefore
  &:first-child {
    margin-right: -1px;
  }

  // addonAfter
  &:last-child {
    margin-left: -1px;
  }
}

.affixWrapper {
  @extend %inputBase;
  display: inline-flex;
  flex: 1;

  &.focused {
    z-index: 2;
  }

  // remove styles from inner input
  > .input {
    min-height: 0;
    border: none;
    outline: none;

    &:focus,
    &.focused {
      box-shadow: none;
    }
  }

  &.error {
    border-color: colors.$red60;
    z-index: 2;

    &.focused {
      box-shadow: 0 0 0 3px colors.$red20;
    }
  }

  // Sizing for affix wrapper (prefix/suffix)
  &.compact {
    min-height: $input-height-compact;

    > .input {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.prefix,
.suffix {
  display: flex;
  flex: none;
  align-items: center;
}

.prefix {
  padding-left: 10px;
}

.suffix {
  padding-right: 10px;
}

.errorIcon {
  margin-right: 5px;
  color: colors.$red60;
}
