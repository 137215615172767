.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  animation: spin 600ms infinite linear;
  display: inline-block;
  border-radius: 50%;
  border-style: solid;
  border-color: rgba(51, 51, 51, 0.16);
  cursor: wait;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
