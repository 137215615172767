@use '~@simon/core/styles/antd';
@use './_variables.scss';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: none;
}

body {
  min-height: -webkit-fill-available;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

h2,
h3 {
  margin: 0;
  font-size: 16px;
  font-weight: normal;
}

// Large label
h4 {
  margin: 0;
  font-size: 18px;
}

// Small label
h4 {
  margin: 0;
  font-size: 10px;
  font-weight: bold;
}

// http://stackoverflow.com/questions/3790935/can-i-hide-the-html5-number-inputs-spin-box
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

dd,
dl {
  margin: 0;
}

a:focus {
  // the one pseudoclass not handled by Ant
  text-decoration: none;
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}

a[disabled] {
  pointer-events: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

hr {
  margin: 0.5em 0;
}

// LastPass icon on password field
#__lpform_password,
#__lpform_password_numspan {
  display: none !important;
}
