@use '~@simon/core/styles/vars.module';

.securityQuestionLabel {
  display: block;
  font-weight: vars.$font-weight-medium;
  margin-bottom: 10px;
}

.oktaPushContainer {
  text-align: center;
}
