@use '~@simon/core/styles/vars.module';
@use '~@simon/core/styles/colors.module';

.goBackContainer {
  margin-top: 25px;
  text-align: center;
  font-size: 0.8rem;
  color: #616061;

  p {
    margin: 3px;
  }
}

.forgotPasswordRow {
  min-height: 35px;
}

.magicLogin {
  display: block;
  margin: 30px auto;
}

.inactivityAlert {
  position: relative;
  z-index: 2;
  height: 40px;
  margin-bottom: 10px;
}

.unifiedPasswordAlert {
  position: relative;
  z-index: 2;
  margin-bottom: 10px;

  .unifiedPasswordAlertDescription > span {
    display: inline-block;
    margin-top: 8px;
  }

  svg {
    color: colors.$blue50;
    transform: translateY(2px);
  }
}
