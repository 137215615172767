html {
  font-size: 1em;
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

body {
  background: #f5f5f5;

  @media print {
    background: none;
  }
}

// Equalweb accessibility button
#INDbtnWrap {
  #INDmenu-btn {
    margin-left: 0px !important;
    display: none !important;
  }
}

// END OLD
