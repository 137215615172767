@use '~@simon/core/styles/vars.module';
@use '~@simon/core/styles/colors.module';

.resendInstructions {
  margin-top: 20px;
  font-size: 1em;
}

.securityQuestionLabel {
  display: block;
  margin-bottom: 5px;

  span {
    display: block;
    margin: 5px 0 10px;
  }
}

.passwordRequirementsTooltip {
  border: 1px solid colors.$blue70;
  border-radius: vars.$border-radius;
  padding: 10px;

  // stylelint-disable-next-line selector-pseudo-class-disallowed-list
  &:global([data-theme~='light'][data-placement^='left']
      > .tippy-arrow::before) {
    width: 10px;
    height: 10px;
    background-color: colors.$white;
    transform: rotate(45deg);
    border-width: 1px !important;
    border-top-color: colors.$blue70;
    border-right-color: colors.$blue70 !important;
  }

  .tooltipContentWrapper {
    margin-top: -6px;
    text-align: left;
    color: colors.$gray05;

    .tooltipTitle {
      font-weight: vars.$font-weight-bold;
      margin-left: -6px;
      line-height: 30px;
    }
  }

  ul {
    list-style-type: disc;
    padding-inline-start: 10px;

    li::marker {
      color: colors.$gray05;
    }
  }
}
