@use '@simon/core/styles/colors.module';
@use '@simon/core/styles/vars.module' as variables;

.listContainer > div {
  max-width: 100% !important;
}

.selectOptionWrapper > div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selectNoMatch {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: hsl(0, 0, 50%);
}

.label {
  padding-left: 10px;
}

// default react-select hover styles but in CSS for perf win
.option {
  background-color: transparent;
  color: inherit;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;

  .clearIcon {
    float: right;
    padding: 0 8px;
  }

  &:hover {
    background-color: #deebff;
    color: inherit;
    cursor: default;
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
  }
}

.dropdownIndicator {
  margin-right: 5px;
  &.rotated {
    transform: rotate(180deg);
  }
}

.clientAccountSelect {
  .option {
    font-size: 12px;
  }
}
