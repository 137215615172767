.btnGroup {
  position: relative;
  display: inline-flex;
  vertical-align: middle;

  > .btn {
    position: relative;
    flex: 1 1 auto;
  }

  // Bring the hover, focused, and "active" buttons to the front to overlay
  // the borders properly
  > .btn:hover,
  > .btn:focus,
  > .btn:active,
  > .btn.active {
    z-index: 1;
  }
}

.btnGroup {
  // Prevent double borders when buttons are next to each other
  > .btn:not(:first-child),
  > .btnGroup:not(:first-child) {
    margin-left: -1px;
  }

  // Remove rounded corners
  > .btn:not(:last-child):not(.dropdownToggle),
  > .btnGroup:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  // The left radius should be 0 if the button is:
  // - the "third or more" child
  // - the second child and the previous element isn't `.btn-check` (making it the first child visually)
  // - part of a btn-group which isn't the first child
  > .btn:nth-child(n + 3),
  > :not(.btnCheck) + button,
  > .btnGroup:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
