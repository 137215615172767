@use '@simon/core/styles/colors.module';
@use '@simon/core/styles/vars.module';

.alert {
  display: flex;
  // align icon with the message
  align-items: center;
  border-radius: vars.$border-radius;
  padding: 8px 15px;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  &.withDescription {
    padding: 15px;
    // align icon with the bottom base line
    align-items: baseline;
  }
  &.withIcon {
    // align icon with the top
    align-items: flex-start;
  }

  // Colors are ecopied from Ant to make transition easier
  // todo: We should replace with our own vars
  &.error {
    background-color: #fff1f0;
    border: 1px solid #ffa39e;
  }
  &.warning {
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
  }
  &.success {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
  }
  &.info {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
  }
  &.share {
    background-color: colors.$green10;
    border-bottom: 4px solid colors.$green30;
  }
}

.close {
  position: absolute;
  margin-left: 1rem;
  right: 1rem;
  cursor: pointer;
}

.icon {
  display: flex;
  margin: 0 15px 0 0;

  &.share {
    top: 3px;
  }
}

.text {
  display: flex;
  flex-flow: column wrap;
  color: colors.$defaultFontColor;
}

.message {
  font-weight: 400;
  font-size: 1em;
  margin: 0;
  padding: 0;

  &.withDescription {
    font-size: 1.1em;
  }
  &.share {
    font-weight: 500;
  }
}

.description {
  font-size: 1em;
  font-weight: 300;
  margin: 0;
  padding: 0;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
