@use 'sass:math';
@use '@simon/core/styles/vars.module';

// Mixins for grid system
@mixin loop-grid-columns($index, $class) {
  @if $index > 0 {
    .col#{$class}-#{$index} {
      display: block;
      flex: 0 0 math.percentage(($index / vars.$simon-grid-columns));
      max-width: math.percentage(($index / vars.$simon-grid-columns));
    }
    .col#{$class}-push-#{$index} {
      left: math.percentage(($index / vars.$simon-grid-columns));
    }
    .col#{$class}-pull-#{$index} {
      right: math.percentage(($index / vars.$simon-grid-columns));
    }
    .col#{$class}-offset-#{$index} {
      margin-left: math.percentage(($index / vars.$simon-grid-columns));
    }
    .col#{$class}-order-#{$index} {
      order: $index;
    }
    @include loop-grid-columns(($index - 1), $class);
  }

  @if $index == 0 {
    .col#{$class}-#{$index} {
      display: none;
    }
    .col-push-#{$index} {
      left: auto;
    }
    .col#{$class}-push-#{$index} {
      left: auto;
    }
    .col-pull-#{$index} {
      right: auto;
    }
    .col#{$class}-pull-#{$index} {
      right: auto;
    }
    .col#{$class}-offset-#{$index} {
      margin-left: 0;
    }
    .col#{$class}-order-#{$index} {
      order: 0;
    }
  }
}

@mixin make-grid($class: '') {
  @include loop-grid-columns(vars.$simon-grid-columns, $class);
}

.row {
  display: flex;
  flex-flow: row wrap;

  &::before,
  &::after {
    display: flex;
  }
}

.row-start {
  justify-content: flex-start;
}

.row-center {
  justify-content: center;
}

.row-end {
  justify-content: flex-end;
}

.row-space-between {
  justify-content: space-between;
}

.row-space-around {
  justify-content: space-around;
}

.row-top {
  align-items: flex-start;
}

.row-middle {
  align-items: center;
}

.row-bottom {
  align-items: flex-end;
}

.col {
  position: relative;
  max-width: 100%;
  // Prevent columns from collapsing when empty
  min-height: 1px;
}

@include make-grid();

// Extra small grid
@include make-grid('-xs');

// Small grid
@media (min-width: vars.$simon-screen-sm-min) {
  @include make-grid('-sm');
}

// Medium grid
@media (min-width: vars.$simon-screen-md-min) {
  @include make-grid('-md');
}

// Large grid
@media (min-width: vars.$simon-screen-lg-min) {
  @include make-grid('-lg');
}

// Extra Large grid
@media (min-width: vars.$simon-screen-xl-min) {
  @include make-grid('-xl');
}

// Extra Extra Large grid
@media (min-width: vars.$simon-screen-xxl-min) {
  @include make-grid('-xxl');
}
