@use '~@simon/core/styles/vars.module';

.securityQuestionLabel {
  display: block;
  font-weight: vars.$font-weight-medium;
  margin-bottom: 10px;
}

.phoneNumberLabel {
  display: block;
  font-weight: vars.$font-weight-medium;
  margin-bottom: 10px;
}

.phoneNumberInput {
  div:first-child {
    max-width: 93px;
  }
}
