@use '~@simon/core/styles/vars.module';
@use '~@simon/core/styles/colors.module';

.networkOptionLink {
  text-decoration: none !important;
  border: 1px solid #e6e6e6;
  display: block;
  margin: 4px 0;
  color: colors.$defaultFontColor !important;
  font-weight: 300;
  border-radius: 3px;
  padding: 4px 14px;

  &.centered {
    text-align: center;
  }

  &.regular {
    padding: 10px 15px;
  }

  &:hover,
  &.selected {
    color: inherit !important;
    border: 1px solid colors.$rowHoverBorderColor;
    background: colors.$rowHoverBackground;
    text-decoration: none;
  }

  // title
  h4 {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  // subtitle
  h5 {
    margin: 0;
    color: colors.$greyLight;
    font-weight: 300;
    font-size: 0.8em;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  color: colors.$gray60;
  margin-top: 6px;

  svg {
    width: 21px;
  }

  p {
    font-size: 14px;
    margin: 2px 0 8px 5px;
  }
}
