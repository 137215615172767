$font-path: '../../../core/fonts';
$roboto: 'Roboto';
$roboto-condensed: 'Roboto Condensed';

@mixin roboto-font($family, $filename, $extn, $weight, $style: normal) {
  @font-face {
    font-family: $family;
    src: url('#{$font-path}/#{$filename}/#{$filename}-#{$extn}.eot');
    src: local('#{$family} #{$extn}'),
      url('#{$font-path}/#{$filename}/#{$filename}-#{$extn}.woff2')
        format('woff2'),
      url('#{$font-path}/#{$filename}/#{$filename}-#{$extn}.woff')
        format('woff'),
      url('#{$font-path}/#{$filename}/#{$filename}-#{$extn}.ttf')
        format('truetype');
    font-display: swap;
    font-weight: $weight;
    font-style: $style;
  }
}

@include roboto-font($roboto, 'Roboto', 'Light', 300);
@include roboto-font($roboto, 'Roboto', 'Regular', 400);
@include roboto-font($roboto, 'Roboto', 'Medium', 500);
@include roboto-font('Roboto Medium', 'Roboto', 'Medium', 500);
@include roboto-font($roboto, 'Roboto', 'Bold', 700);
@include roboto-font($roboto, 'Roboto', 'Bold', 800);

@include roboto-font($roboto-condensed, 'RobotoCondensed', 'Light', 300);
@include roboto-font($roboto-condensed, 'RobotoCondensed', 'Regular', 400);
@include roboto-font($roboto-condensed, 'RobotoCondensed', 'Bold', 700);

// patch legacy Univers fonts with Roboto equivalents
$univers: 'Univers';
$univers-condensed: 'Univers Condensed';

@include roboto-font($univers-condensed, 'RobotoCondensed', 'Light', 100);
@include roboto-font($univers-condensed, 'RobotoCondensed', 'Regular', normal);
@include roboto-font($univers-condensed, 'RobotoCondensed', 'Bold', bold);
@include roboto-font($univers, 'Roboto', 'Regular', normal);
@include roboto-font($univers, 'Roboto', 'Bold', bold);
