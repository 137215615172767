@use '@simon/core/styles/vars.module';
@use '@simon/core/styles/colors.module';

$btn-icon-size: 40px;
$btn-padding-y: 7px;
$btn-padding-x: 27px;
$btn-font-size: 16px;
$btn-line-height: 24px;
$btn-border-radius: 40px;

$btn-icon-size-lg: 48px;
$btn-padding-y-lg: 11px;
$btn-padding-x-lg: 31px;
$btn-font-size-lg: 16px;
$btn-line-height-lg: 24px;
$btn-border-radius-lg: 100px;

$btn-icon-size-sm: 32px;
$btn-padding-y-sm: 5px;
$btn-padding-x-sm: 23px;
$btn-font-size-sm: 14px;
$btn-line-height-sm: 20px;
$btn-border-radius-sm: 20px;

$btn-font-weight: 500;
$btn-transition: background-color 200ms cubic-bezier(0.2, 0.8, 0.4, 1),
  box-shadow 200ms cubic-bezier(0.2, 0.8, 0.4, 1),
  border 200ms cubic-bezier(0.2, 0.8, 0.4, 1);

$variants: (
  // Primary variation
  btn-primary-color: colors.$white,
  btn-primary-bg: colors.$blue60,
  btn-primary-border: colors.$blue60,
  btn-primary-hover-color: colors.$white,
  btn-primary-hover-bg: colors.$blue55,
  btn-primary-hover-border: colors.$blue55,
  btn-primary-focus-color: colors.$white,
  btn-primary-focus-bg: colors.$blue55,
  btn-primary-focus-border: colors.$blue55,
  btn-primary-active-color: colors.$white,
  btn-primary-active-bg: colors.$blue70,
  btn-primary-active-border: colors.$blue70,
  btn-primary-disabled-color: colors.$gray40,
  btn-primary-disabled-bg: colors.$gray15,
  btn-primary-disabled-border: colors.$gray15,
  // Secondary variation
  btn-secondary-color: colors.$blue60,
  btn-secondary-bg: colors.$white,
  btn-secondary-border: colors.$blue60,
  btn-secondary-hover-color: colors.$blue55,
  btn-secondary-hover-bg: colors.$white,
  btn-secondary-hover-border: colors.$blue55,
  btn-secondary-focus-color: colors.$blue55,
  btn-secondary-focus-bg: colors.$white,
  btn-secondary-focus-border: colors.$blue50,
  btn-secondary-active-color: colors.$blue70,
  btn-secondary-active-bg: colors.$white,
  btn-secondary-active-border: colors.$blue70,
  btn-secondary-disabled-color: colors.$gray40,
  btn-secondary-disabled-bg: colors.$white,
  btn-secondary-disabled-border: colors.$gray20,
  // Tertiary variation
  btn-tertiary-color: colors.$gray70,
  btn-tertiary-bg: colors.$white,
  btn-tertiary-border: colors.$gray30,
  btn-tertiary-hover-color: colors.$gray60,
  btn-tertiary-hover-bg: colors.$white,
  btn-tertiary-hover-border: colors.$gray20,
  btn-tertiary-focus-color: colors.$gray60,
  btn-tertiary-focus-bg: colors.$white,
  btn-tertiary-focus-border: colors.$blue50,
  btn-tertiary-active-color: colors.$gray80,
  btn-tertiary-active-bg: colors.$white,
  btn-tertiary-active-border: colors.$gray40,
  btn-tertiary-disabled-color: colors.$gray40,
  btn-tertiary-disabled-bg: colors.$white,
  btn-tertiary-disabled-border: colors.$gray20,
  // Ghost variation
  btn-ghost-color: colors.$blue60,
  btn-ghost-bg: transparent,
  btn-ghost-border: transparent,
  btn-ghost-hover-color: colors.$blue55,
  btn-ghost-hover-bg: colors.$gray10,
  btn-ghost-hover-border: colors.$gray10,
  btn-ghost-focus-color: colors.$blue60,
  btn-ghost-focus-bg: colors.$white,
  btn-ghost-focus-border: colors.$blue50,
  btn-ghost-active-color: colors.$blue70,
  btn-ghost-active-bg: colors.$blue10,
  btn-ghost-active-border: colors.$blue10,
  btn-ghost-disabled-color: colors.$gray40,
  btn-ghost-disabled-bg: colors.$white,
  btn-ghost-disabled-border: colors.$white,
  // Branding variation
  btn-branding-color: colors.$white,
  btn-branding-bg: colors.$blue50,
  btn-branding-border: colors.$blue50,
  btn-branding-hover-color: colors.$white,
  btn-branding-hover-bg: colors.$blue55,
  btn-branding-hover-border: colors.$blue55,
  btn-branding-focus-color: colors.$white,
  btn-branding-focus-bg: colors.$blue55,
  btn-branding-focus-border: colors.$blue55,
  btn-branding-active-color: colors.$white,
  btn-branding-active-bg: colors.$blue70,
  btn-branding-active-border: colors.$blue70,
  btn-branding-disabled-color: colors.$gray40,
  btn-branding-disabled-bg: colors.$gray15,
  btn-branding-disabled-border: colors.$gray15,
  // Danger variation
  btn-danger-color: colors.$white,
  btn-danger-bg: colors.$red60,
  btn-danger-border: colors.$red60,
  btn-danger-hover-color: colors.$white,
  btn-danger-hover-bg: colors.$red50,
  btn-danger-hover-border: colors.$red50,
  btn-danger-focus-color: colors.$white,
  btn-danger-focus-bg: colors.$red60,
  btn-danger-focus-border: colors.$red60,
  btn-danger-active-color: colors.$white,
  btn-danger-active-bg: colors.$red70,
  btn-danger-active-border: colors.$red70,
  btn-danger-disabled-color: colors.$white,
  btn-danger-disabled-bg: colors.$red30,
  btn-danger-disabled-border: colors.$red30,
  // Warning variation
  btn-warning-color: colors.$white,
  btn-warning-bg: colors.$yellow50,
  btn-warning-border: colors.$yellow50,
  btn-warning-hover-color: colors.$white,
  btn-warning-hover-bg: colors.$yellow40,
  btn-warning-hover-border: colors.$yellow40,
  btn-warning-focus-color: colors.$white,
  btn-warning-focus-bg: colors.$yellow50,
  btn-warning-focus-border: colors.$yellow50,
  btn-warning-active-color: colors.$white,
  btn-warning-active-bg: colors.$yellow60,
  btn-warning-active-border: colors.$yellow60,
  btn-warning-disabled-color: colors.$white,
  btn-warning-disabled-bg: colors.$yellow20,
  btn-warning-disabled-border: colors.$yellow20,
  // Success variation
  btn-success-color: colors.$white,
  btn-success-bg: colors.$green50,
  btn-success-border: colors.$green50,
  btn-success-hover-color: colors.$white,
  btn-success-hover-bg: colors.$green40,
  btn-success-hover-border: colors.$green40,
  btn-success-focus-color: colors.$white,
  btn-success-focus-bg: colors.$green50,
  btn-success-focus-border: colors.$green50,
  btn-success-active-color: colors.$white,
  btn-success-active-bg: colors.$green60,
  btn-success-active-border: colors.$green60,
  btn-success-disabled-color: colors.$white,
  btn-success-disabled-bg: colors.$green20,
  btn-success-disabled-border: colors.$green20
);

@mixin button-variant($variant) {
  color: map-get($variants, btn-#{$variant}-color);
  background-color: map-get($variants, btn-#{$variant}-bg);
  border-color: map-get($variants, btn-#{$variant}-border);

  &:hover {
    color: map-get($variants, btn-#{$variant}-hover-color);
    background-color: map-get($variants, btn-#{$variant}-hover-bg);
    border-color: map-get($variants, btn-#{$variant}-hover-border);
  }

  &.focus {
    color: map-get($variants, btn-#{$variant}-focus-color);
    background-color: map-get($variants, btn-#{$variant}-focus-bg);
    border-color: map-get($variants, btn-#{$variant}-focus-border);
    box-shadow: 0 0 0 3px colors.$blue30;
  }

  &:active,
  &.active {
    color: map-get($variants, btn-#{$variant}-active-color);
    background-color: map-get($variants, btn-#{$variant}-active-bg);

    &:not(.focus) {
      border-color: map-get($variants, btn-#{$variant}-active-border);
    }
  }

  &:disabled,
  &.disabled {
    color: map-get($variants, btn-#{$variant}-disabled-color);
    background-color: map-get($variants, btn-#{$variant}-disabled-bg);
    border-color: map-get($variants, btn-#{$variant}-disabled-border);
  }

  &.action {
    color: colors.$white;
    background-color: colors.$green40;
    border-color: colors.$green40;

    &:active,
    &.active {
      &:not(.focus) {
        border-color: colors.$green40;
      }
    }

    &.focus {
      box-shadow: 0 0 0 3px colors.$green20;
    }
  }
}

.button {
  display: inline-block;
  padding: $btn-padding-y $btn-padding-x;
  font-family: vars.$font-family-default;
  font-size: $btn-font-size;
  font-weight: $btn-font-weight;
  line-height: $btn-line-height;
  color: colors.$blue80;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: $btn-border-radius;
  transition: $btn-transition;

  &:focus {
    outline: 0;
  }

  .active {
    // Generated by button-variant mixin
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
  }

  &.block {
    display: block;
    width: 100%;
  }

  &.block + &.block {
    margin-top: 0.5rem;
  }

  > svg {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
  }

  > svg + span,
  > span + svg {
    margin-left: 8px;
  }

  &.circle {
    height: $btn-icon-size;
    width: $btn-icon-size;
    padding: 0;
    border-radius: 50%;
  }

  &.large {
    padding: $btn-padding-y-lg $btn-padding-x-lg;
    font-size: $btn-font-size-lg;
    line-height: $btn-line-height-lg;
    border-radius: $btn-border-radius-lg;

    &.circle {
      height: $btn-icon-size-lg;
      width: $btn-icon-size-lg;
      padding: 0;
    }
  }

  &.small {
    padding: $btn-padding-y-sm $btn-padding-x-sm;
    font-size: $btn-font-size-sm;
    line-height: $btn-line-height-sm;
    border-radius: $btn-border-radius-sm;

    &.circle {
      height: $btn-icon-size-sm;
      width: $btn-icon-size-sm;
      padding: 0;
    }
  }
}

.loading {
  display: inline-block;
  margin-right: 8px;
  margin-left: -8px;

  > div {
    cursor: inherit;
    vertical-align: text-top;
  }
}

.primary {
  @include button-variant(primary);
}

.branding {
  @include button-variant(branding);
}

.secondary {
  @include button-variant(secondary);
}

.tertiary {
  @include button-variant(tertiary);
}

.ghost {
  @include button-variant(ghost);
}

.danger {
  @include button-variant(danger);

  &.focus {
    box-shadow: 0 0 0 3px colors.$red30;
  }
}

.warning {
  @include button-variant(warning);
}

.success {
  @include button-variant(success);
}

.link {
  @include button-variant(ghost);

  &:hover {
    background-color: map-get($variants, btn-ghost-bg);
    border-color: map-get($variants, btn-ghost-border);
  }

  &.focus {
    color: map-get($variants, btn-ghost-color);
    background-color: map-get($variants, btn-ghost-bg);
    border-color: map-get($variants, btn-ghost-focus-border);
    box-shadow: 0 0 0 3px colors.$blue30;
  }

  &:active,
  &.active {
    color: map-get($variants, btn-ghost-active-color);
    background-color: map-get($variants, btn-ghost-bg);

    &:not(.focus) {
      border-color: map-get($variants, btn-ghost-border);
    }
  }

  &:hover,
  &.focus,
  &:active,
  &.active {
    text-decoration: underline;
  }
}
