@use '@simon/core/styles/colors.module';

.select {
  .clearIndicator {
    color: colors.$gray20;
    padding-left: 4px;
    padding-right: 4px;
    cursor: pointer;

    &:hover {
      color: colors.$gray30;
    }
  }

  .dropdownIndicator {
    padding: 8px 12px;
    color: colors.$gray70;
    transition: transform 150ms ease-out;

    &.isOpen {
      transform: rotate(-180deg);
    }
  }

  .indicatorSeparator {
    display: flex;
    padding: 8px 4px;
    color: colors.$red60;
  }

  .valueLabel {
    padding-left: 10px;
  }

  .spanCheckbox span {
    margin-top: 0;
    margin-left: 6px;
  }

  // Ant Design Checkbox: Remove after Checkbox component is standardized
  .option {
    padding-top: 8px;
    padding-bottom: 8px;
    margin-left: 0px;

    // stylelint-disable-next-line selector-pseudo-class-disallowed-list
    :global(.ant-checkbox-wrapper) {
      color: colors.$gray90;
      pointer-events: none; // Bypass checkbox onClick
    }

    // stylelint-disable-next-line selector-pseudo-class-disallowed-list
    :global(.ant-checkbox-inner) {
      border-color: colors.$gray30;
      transition: none;

      &:after {
        transition: none;
      }
    }

    // stylelint-disable-next-line selector-pseudo-class-disallowed-list
    :global(.ant-checkbox-checked .ant-checkbox-inner) {
      border-color: colors.$blue60;
      background-color: colors.$blue60;
    }

    &:hover,
    &.focused {
      // stylelint-disable-next-line selector-pseudo-class-disallowed-list
      :global(.ant-checkbox-inner) {
        border-color: colors.$blue60;
      }
    }

    &.disabled {
      // stylelint-disable-next-line selector-pseudo-class-disallowed-list
      :global(.ant-checkbox-wrapper) {
        color: colors.$gray30;
      }

      // stylelint-disable-next-line selector-pseudo-class-disallowed-list
      :global(.ant-checkbox-inner) {
        border-color: colors.$gray30;
      }

      // stylelint-disable-next-line selector-pseudo-class-disallowed-list
      :global(.ant-checkbox-checked .ant-checkbox-inner) {
        border-color: colors.$gray13;
        background-color: colors.$gray13;
      }

      // stylelint-disable-next-line selector-pseudo-class-disallowed-list
      :global(.ant-checkbox-inner) {
        border-color: colors.$gray15;
        background-color: colors.$white;

        &:after {
          border-color: colors.$gray40;
        }
      }

      // stylelint-disable-next-line selector-pseudo-class-disallowed-list
      :global(.ant-checkbox-disabled + span) {
        color: colors.$gray30;
      }
    }
  }
}

.multiValueCount {
  background-color: colors.$gray10;
  border-radius: 2px;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px colors.$gray15;

  > span {
    border-radius: 2px;
    color: colors.$gray90;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 2px 6px;
    line-height: 20px;
  }
}
