/* Text */
$simon-font-family: 'Roboto', Helvetica, Arial, sans-serif;

/* Colors */
/* TODO Create a color palette for each primary color 100-900 */
$white: #fff;
$black: #000;
$simon-text-light-blue: #396da8;
$simon-font-color-pale-gray: #e8eaed;
$simon-font-color-lightest-gray: #9c9c9c;
$simon-font-color-gray: #9e9e9e;
$simon-font-color-cool-gray: #9aa5b2;
$simon-font-color-dark-gray: #858998;
$simon-font-color-light-gray: #757575;
$simon-font-color-spectrum-bar-chart-gray: #666;
$simon-font-color-alt-gray: #666;
$simon-font-color-light-blue: #2f66a5;
$simon-font-color-lighter-blue: #3d7bc4;
$simon-font-color-blue: #3f7cc3;
$simon-font-color-dark-blue: #333a53;
$simon-font-color-navy-blue: #265386;
$simon-font-color-error: #f5222e;
$simon-font-color-success: #6eb422;
$simon-font-color-warning: #dd2e2f;
$simon-font-color-label-gray: #888888;
$simon-font-color-label-dark-gray: #666666;
$simon-font-color-light-green: #e8f7ed;
$simon-font-color-dark-green: #24a148;
$simon-font-color-disclaimer: #9b9b9b;

$charcoal: #272727;
$black-pearl: #121619;

$light-blue: #4a90e2;
$medium-blue: #2d3f72;
$dark-blue: #1e4068;
$light-green: #4dae50;
$dark-green: #3b853d;
$medium-gray: #d5d5d5;
$dark-gray: #6a788a;
$dark-black-gray: #353e48;

// Font Weight
$simon-font-weight-bold: 500;
$simon-font-weight-light: 200;

/* Borders */
$simon-border-color-light-gray: #e2e2e2;
$simon-border-color-medium-gray: #e9e9e9;
$simon-border-color-dark-gray: #c2c2c2;
$simon-border-color-gray: #9e9e9e;
$simon-border-outline-header: solid 1px $medium-gray;
$simon-border-color-warning: #dd2e2f;

$simon-border-light-gray: 1px solid $simon-border-color-light-gray;
$simon-border-medium-gray: 1px solid $simon-border-color-medium-gray;
$simon-border-dark-gray: 1px solid $simon-border-color-dark-gray;
$simon-border-gray: 1px solid $simon-border-color-gray;
$simon-input-border: 1px solid #cccccc;
$simon-modal-border: 1px solid #cfcfcf;

/* Backgrounds */
$simon-background-light-gray: #f7f7f7;
$simon-offerings-wrapper-background: #f6f6f6;

/* Gradients */
$simon-gradient-to-bottom-gray: linear-gradient(to bottom, #eeeeee, #d8d8d8);
$simon-gradient-gray-heading: linear-gradient(360deg, #f6f6f6 0%, #ffffff 100%);
$simon-gradient-gray-title: linear-gradient(0deg, #fff 0%, #f0f0f0 100%);
$simon-gradient-gray-lighter: linear-gradient(180deg, #f5f5f5 0%, #ffffff 100%);
$simon-gradient-background-blue: linear-gradient(299.08deg, #3e4d85, #232b55);
$simon-gradient-background-dark-blue: linear-gradient(
    302.38deg,
    rgba(0, 0, 0, 3.5e-5) -14.83%,
    rgba(3, 16, 31, 0.35) 129.15%
  ),
  #021d42;

/* Shadows */
$radio-button-shadow: 0 8px 15px 10px rgba(0, 0, 0, 0.1);
$tool-tip-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.2);
$box-shadow-default: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
$box-shadow-light: 0px 2px 2px rgba(0, 0, 0, 0.05);
$bottom-only-box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);

/* Unified UI Breakpoints */
/* Work in progress by Sahat Yalkabov Jan/2021 */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
);

/* Modal z-indecies */
$modal-zIndex: 9000050;
$dropdown-zIndex: 9000051;
$modal-backdrop-zIndex: 9000000;
$slide-up-modal-zIndex: 600;
$navbar-zIndex: 500;

/* ------------ COPIED FROM SIMON.IO ------------ */

/* Breakpoints */
// todo: replace usages in favor of `$simon-screen-xxxx` vars from below
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-x-large: 1180px;

// Resolution
$hdpi: 192dpi;

/*
  SIZE VARIABLES
*/
$padding-default: 20px;

$max-size: $breakpoint-x-large + ($padding-default * 2);
$border-radius: 5px;
$border-radius-secondary: 10px;
$border-radius-button: 24px;
$inputBorderRadius: 4px;

/*
 FONTS
 - font based on 1rem = 16px
*/
$fontSize10: 0.625rem; // 10px
$fontSize12: 0.75rem; // 13px
$fontSize13: 0.813rem; // 13px
$fontSize14: 0.875rem; // 14px
$fontSize15: 0.9375rem; // 15px
$fontSize16: 1rem; // 16px
$fontSize18: 1.125rem; // 18px
$fontSize20: 1.25rem; // 20px
$fontSize22: 1.375rem; // 22px
$fontSize23: 1.4375rem; // 23px
$fontSize24: 1.5rem; // 24px
$fontSize26: 1.625rem; // 26px
$fontSize28: 1.75rem; // 28px
$fontSize30: 1.875rem; // 30px
$fontSize32: 2rem; // 32px
$fontSize34: 2.125rem; // 34px
$fontSize38: 2.375rem; // 38px
$fontSize40: 2.5rem; // 40px
$fontSize42: 2.625rem; // 42px
$fontSize44: 2.75rem; // 44px
$fontSize52: 3.25rem; // 52px
$fontSize56: 3.5rem; // 56px
$fontSize64: 4rem; // 56px

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: bold;
$font-weight-strong: 700;

$font-family-default: 'Roboto', 'Segoe UI', sans-serif;
$font-family-medium: 'Roboto Medium', 'Segoe UI', sans-serif;

/*
  COLORS
*/
$blue-dark: #333a53;
$blue-black: #141e3c;
$blue-navy: #2f66a5;
$blue-vivid: #4a8fe2;

$purple-vivid: #2c3e70;

$grey-dark: #4c4c4c;
$grey-dark2: #4b4b4b;
$grey-secondary: #666;
$grey-disabled: #ececec;
$grey-light: #999;
$grey-light-secondary: #ececec;
$grey-blue: #617184;

$grey-light-background-primary: #fafafa;
$grey-light-background-secondary: #f5f5f5;
$grey-dark-background-primary: #e1e1e1;
$grey-separator: #ebebeb;

$white: #ffffff;
$black: #000000;

/*
 TIME
*/
$animation-time: 0.75s;
$transition-time: 0.75s;

/* NEW UNIFIED GRID SYSTEM */
/* Grid */
$simon-grid-columns: 24;

// Media queries breakpoints
// Extra small screen / phone
$simon-screen-xs: 480px;
$simon-screen-xs-min: $simon-screen-xs;

// Small screen / tablet
$simon-screen-sm: 576px;
$simon-screen-sm-min: $simon-screen-sm;

// Medium screen / desktop
$simon-screen-md: 768px;
$simon-screen-md-min: $simon-screen-md;

// Large screen / wide desktop
$simon-screen-lg: 992px;
$simon-screen-lg-min: $simon-screen-lg;

// Extra large screen / full hd
$simon-screen-xl: 1200px;
$simon-screen-xl-min: $simon-screen-xl;

// Extra extra large screen / large desktop
$simon-screen-xxl: 1600px;
$simon-screen-xxl-min: $simon-screen-xxl;

// provide a maximum
$simon-screen-xs-max: ($simon-screen-sm-min - 1px);
$simon-screen-sm-max: ($simon-screen-md-min - 1px);
$simon-screen-md-max: ($simon-screen-lg-min - 1px);
$simon-screen-lg-max: ($simon-screen-xl-min - 1px);
$simon-screen-xl-max: ($simon-screen-xxl-min - 1px);

//  Slide Up Modal Consts
$slide-up-modal-border-top: 16px;

:export {
  breakPointSm: $breakpoint-small;
  breakPointSm1: $breakpoint-small + 1;
  breakPointMd: $breakpoint-medium;
  breakPointMd1: $breakpoint-medium + 1;
  breakPointLg: $breakpoint-large;
  breakPointLg1: $breakpoint-large + 1;
  breakPointSimonXl: $simon-screen-xl + 1;
  mdpi: $hdpi - 1;
  hdpi: $hdpi;
  modalZIndex: $modal-zIndex;
  aboveModalZIndex: $modal-zIndex + 1;
  belowModalZIndex: $modal-backdrop-zIndex - 1;
  simonGradientBackgroundBlue: $simon-gradient-background-blue;
  simonGradientBackgroundDarkBlue: $simon-gradient-background-dark-blue;
  simonBorderMediumGray: $simon-border-medium-gray;
  simonBorderDarkGray: $simon-border-dark-gray;
  simonFontColorDisclaimer: $simon-font-color-disclaimer;
}
