/* stylelint-disable */
// copy of '~antd/dist/antd.less'; but only with the stuff that we're actually using
@import '~antd/lib/style/index.less';
//@import "~antd/lib/affix/style/index.less";
@import '~antd/lib/alert/style/index.less';
//@import "~antd/lib/anchor/style/index.less";
//@import "~antd/lib/auto-complete/style/index.less";
@import '~antd/lib/avatar/style/index.less';
//@import "~antd/lib/back-top/style/index.less";
@import '~antd/lib/badge/style/index.less';
@import '~antd/lib/breadcrumb/style/index.less';
@import '~antd/lib/button/style/index.less';
//@import "~antd/lib/calendar/style/index.less";
@import '~antd/lib/card/style/index.less';
//@import "~antd/lib/carousel/style/index.less";
//@import "~antd/lib/cascader/style/index.less";
@import '~antd/lib/checkbox/style/index.less';
@import '~antd/lib/collapse/style/index.less';
//@import "~antd/lib/comment/style/index.less";
//@import "~antd/lib/config-provider/style/index.less";
@import '~antd/lib/date-picker/style/index.less';
//@import "~antd/lib/descriptions/style/index.less";
@import '~antd/lib/divider/style/index.less';
//@import "~antd/lib/drawer/style/index.less";
@import '~antd/lib/dropdown/style/index.less';
//@import "~antd/lib/empty/style/index.less";
@import '~antd/lib/form/style/index.less';
@import '~antd/lib/grid/style/index.less';
@import '~antd/lib/icon/style/index.less';
@import '~antd/lib/input/style/index.less';
@import '~antd/lib/input-number/style/index.less';
//@import "~antd/lib/layout/style/index.less";
@import '~antd/lib/list/style/index.less';
//@import "~antd/lib/locale-provider/style/index.less";
//@import "~antd/lib/mention/style/index.less";
//@import "~antd/lib/mentions/style/index.less";
@import '~antd/lib/menu/style/index.less';
@import '~antd/lib/message/style/index.less';
@import '~antd/lib/modal/style/index.less';
@import '~antd/lib/notification/style/index.less';
//@import "~antd/lib/page-header/style/index.less";
@import '~antd/lib/pagination/style/index.less';
@import '~antd/lib/popover/style/index.less';
@import '~antd/lib/progress/style/index.less';
@import '~antd/lib/radio/style/index.less';
//@import "~antd/lib/rate/style/index.less";
//@import "~antd/lib/result/style/index.less";
@import '~antd/lib/select/style/index.less';
@import '~antd/lib/skeleton/style/index.less';
@import '~antd/lib/slider/style/index.less';
@import '~antd/lib/spin/style/index.less';
//@import "~antd/lib/statistic/style/index.less";
//@import "~antd/lib/steps/style/index.less";
//@import "~antd/lib/switch/style/index.less";
@import '~antd/lib/table/style/index.less';
//@import "~antd/lib/tabs/style/index.less";
@import '~antd/lib/tag/style/index.less';
@import '~antd/lib/time-picker/style/index.less';
@import '~antd/lib/timeline/style/index.less';
@import '~antd/lib/tooltip/style/index.less';
//@import "~antd/lib/transfer/style/index.less";
@import '~antd/lib/tree/style/index.less';
@import '~antd/lib/tree-select/style/index.less';
//@import "~antd/lib/typography/style/index.less";
@import '~antd/lib/upload/style/index.less';
/* stylelint-enable */

//////////////////////////////////////////////////////////////////////////////////////
// KEEP IN SYNC WITH ./antd.scss
// variables overrides
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
//////////////////////////////////////////////////////////////////////////////////////
@main-theme-gradient-start: #636c8c;
@main-theme-gradient-end: #343a53;

@primary-color: #2f66a5;
@success-color: #6fb422;
@error-color: #f5222d;
@disabled-color: #cecece;

@tabs-title-font-size: 16px;

// Base Scaffolding Variables
// ---

// Background color for `<body>`
@body-background: #fff;
// Base background color for most components
@component-background: #fff;
@font-family-no-number: Helvetica, Arial, sans-serif;
// Roboto is loaded by style/fonts/roboto.scss
@font-family: Roboto, @font-family-no-number;
@code-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
  monospace;
@heading-color: #333a53;
@text-color: @heading-color;
@text-color-secondary: fade(@text-color, 85%);
@heading-color-dark: #fff;
@text-color-dark: @heading-color-dark;
@text-color-secondary-dark: fade(@text-color-dark, 85%);
@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;
@line-height-base: 1.5;
@border-radius-base: 4px;
@border-radius-sm: 2px;

// vertical paddings
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-2xs: @padding-xs / 2;

// LINK
@link-color: @primary-color;
// default Ant link highlighting is a bit hard to notice, intensified it a bit
@link-hover-color: none; // remove hover effect
@link-active-color: none; //remove active effect
@link-decoration: none;
@link-hover-decoration: none;

// Animation
@animation-duration-super-slow: 0s; // video player playlist slide-out
@animation-duration-slow: 0s; // Modal
@animation-duration-base: 0s;
@animation-duration-fast: 0s; // Tooltip

// Remove default row hover
@table-row-hover-bg: undefined;

// Menu
// ---
@menu-inline-toplevel-item-height: 60px;
// light (top)
@menu-bg: #fff;
@menu-item-active-bg: #f2f2f2;
// dark (sidebar)
@menu-dark-color: #fff;
@menu-dark-bg: none;
@menu-dark-arrow-color: #fff;
@menu-dark-submenu-bg: none;
@menu-dark-highlight-color: #fff;
@menu-dark-item-active-bg: #353c55;

// Border Color
@border-color-base: #c2c2c2;
@border-color-split: #c2c2c2;

// Layout
@layout-body-background: #f0f2f5;
@layout-header-background: linear-gradient(180deg, #f8f8f8 0%, #ffffff 100%);
@layout-footer-background: @layout-body-background;
@layout-header-height: 82px;
@layout-header-padding: 0;
@layout-footer-padding: 24px 50px;
@layout-sider-background: @main-theme-gradient-start;
@layout-trigger-height: 48px;
@layout-trigger-background: #002140;
@layout-trigger-color: #fff;
@layout-zero-trigger-width: 36px;
@layout-zero-trigger-height: 42px;
// Layout light theme
@layout-sider-background-light: #fff;
@layout-trigger-background-light: #fff;
@layout-trigger-color-light: @text-color;

// Modal
@modal-mask-bg: rgba(0, 0, 0, 0.2);
@modal-content__box-shadow: 0 0 60px 10px rgba(0, 0, 0, 0.2);
@modal-content__bg: #f7f7f7;

// Buttons
@btn-border-radius-base: 8px;

// Calendar Picker
// The dropdowns were changed to be at z-index 9000051 to account for the olark button
// This is causing the calendar picker to appear below the dropdown
@dropdown-zIndex: 9000051;
//////////////////////////////////////////////////////////////////////////////////////
// end ant overrides
//////////////////////////////////////////////////////////////////////////////////////
.ant-notification-notice-message {
  word-break: break-word;
}

.ant-notification {
  z-index: @dropdown-zIndex;
}

.ant-tooltip-placement-top,
.ant-tooltip-placement-bottom {
  .ant-tooltip-inner {
    text-align: center;
  }
}

// make loading elements less visible than they are by default
.ant-spin-blur {
  opacity: 1;

  & > * {
    opacity: 0.45;
  }
  &:after {
    opacity: 1;
    background: transparent;
  }
}

// make loader for Ant table be only over rows
.ant-table-wrapper {
  .ant-table-placeholder {
    min-height: 66px;
  }

  .ant-spin-spinning {
    transform: translateY(27.5px);
  }

  td .ant-spin-spinning {
    transform: none;
  }

  .ant-table-header {
    margin: 0 !important;
    overflow: visible !important;
  }

  .ant-table-body {
    margin: 0;
    overflow-y: auto !important;
  }

  &.has-scrollbar {
    .ant-table-header {
      // artificially push scrollbar width
      margin: 0 17px 0 0 !important;
    }
  }
}

.ant-table-row-expand-icon {
  line-height: 15px !important;
}

// allow disabling modal buttons based on modal state
.ant-modal {
  @ant-modal-vertical-padding: 24px;
  padding-bottom: @ant-modal-vertical-padding; // this property is given explicity by lib so need to override explicity, not through just "padding"
  padding-top: @ant-modal-vertical-padding;
  @ant-modal-total-vertical-padding: 24px;
  position: relative;
  @ant-modal-vertical-padding: 24px;
  @ant-modal-total-vertical-padding: 24px;

  // read through IE11 issues, think this use of flexbox is safe https://caniuse.com/#feat=flexbox
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    box-shadow: @modal-content__box-shadow;
    background: @modal-content__bg;
    border: 1px solid #cfcfcf;

    .ant-modal-body {
      display: flex;
      flex-direction: column;

      flex-grow: 1;
      overflow: auto;
    }
  }

  .ant-table-content {
    background: #fff;
  }

  // we want to use confirm method
  &.ant-confirm {
    .ant-confirm-body > .anticon {
      display: none;
    }

    .ant-confirm-content {
      margin-left: 0;
    }
  }

  .ant-modal-body-header {
    margin-bottom: @padding-md;
  }
}

.ant-modal-close {
  background: none !important;
  box-shadow: none !important;
  height: auto;
  text-shadow: none;
  color: #999;
}

.ant-table-column-sorter {
  position: static;

  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    position: static;
    &::after {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
    &.on::after {
      pointer-events: none;
    }
  }

  // start with toggling "up" arrow
  .ant-table-column-sorter-up.off + .ant-table-column-sorter-down.off::after {
    pointer-events: none;
  }
}

caption {
  caption-side: initial;
}

.ant-calendar-picker-container {
  // Increasing the z-index by 1 so it appears above the dropdown
  z-index: @dropdown-zIndex + 1;
}

.ant-time-picker-panel {
  // Increasing the z-index by 1 so it appears above modal
  z-index: @dropdown-zIndex + 1;
}

.ant-calendar-time-picker-select {
  overflow-x: hidden;
  overflow-y: scroll !important;
}

.ant-select {
  width: 100%;
}

.ant-select-dropdown {
  z-index: @dropdown-zIndex;
}

.ant-input-number {
  width: 100%;
}

.ant-tree li .ant-tree-node-content-wrapper {
  &.ant-tree-node-selected {
    background-color: transparent;
  }

  &:hover {
    background-color: #d8e0e6;
  }
}

.ant-badge-dot {
  z-index: 1;
}

.ant-message {
  // Increasing the z-index by 1 so it appears above the dropdown and modal
  z-index: @dropdown-zIndex + 1;
}
