@use '@simon/core/styles/colors.module';

.dropdownMenu {
  display: none;
  margin-bottom: 0;
  padding: 8px 0;
  background-color: colors.$white;
  box-shadow: 0 0 1px rgba(51, 58, 83, 0.3), 0 4px 8px rgba(51, 58, 83, 0.25);
  border-radius: 4px;
  list-style: none;
  white-space: nowrap;
  z-index: 11;

  &.show {
    display: block;
  }
}
